!(function (document) {

    'use strict';

    document.addEventListener('DOMContentLoaded', () => {
        const loadMoreButton = document.querySelector('a#load-more-articles');
        if (!loadMoreButton) return;

        loadMoreButton.addEventListener('click', (event) => {
            event.preventDefault();
            // TODO: Switch to sth else to get the fragment; other than emulate the old ajax call
            fetch(loadMoreButton.href, {headers: {"X-Requested-With": "XMLHttpRequest"}})
                .then((response) => response.text())
                .then((html) => {
                    const container = document.getElementById('infinite-container');
                    const fragmentDocument = new DOMParser().parseFromString(html, 'text/html');
                    // Need to query next URL here before we move the elements
                    const nextUrl = fragmentDocument.querySelector('[data-pagination-next]')?.dataset?.paginationNext;
                    const teaser = Array.from(fragmentDocument.body.childNodes);
                    container.append(...teaser);

                    if (nextUrl) {
                        loadMoreButton.setAttribute('href', nextUrl);
                    } else {
                        loadMoreButton.remove();
                    }
                })
                .catch(() => loadMoreButton.remove());
        });
    });
})(document);
