!(function (window, document, sso) {

    'use strict';

    const gaReady = sso.init.production && sso.init.analyticsId && !sso.init.gaPrivacyCookie;

	const handler = ({provider, newWindowConfig}) => {
		const link = document.querySelector(`a[data-share=${provider}]`);
		if (!link) return;

		link.addEventListener('click', (event) => {
			if (newWindowConfig) event.preventDefault();
			if (gaReady) {
				ga('send', 'social', provider, 'share-button', link.href);
			}
			if (newWindowConfig) window.open(link.href, '', newWindowConfig);
		});
	}

	document.addEventListener('DOMContentLoaded', () => {
		handler({provider: 'facebook', newWindowConfig: 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,location=0,status=no,width=600,height=400'});
		handler({provider: 'twitter', newWindowConfig: 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'});
		handler({provider: 'pinterest'});
		handler({provider: 'whatsapp'});
	});
})(window, document, window.sso);
